// eslint-disable-next-line @nx/enforce-module-boundaries
import dynamic from 'next/dynamic';

export default {
  /* APPS */
  Registration: dynamic(
    () => import('@apps/accessmanagement/be/eneco/native/src/components/Registration/Registration'),
  ),
  AppLandingPage: dynamic(
    () => import('@apps/accessmanagement/be/eneco/native/src/components/AppLandingPage/AppLandingPage'),
  ),
  AppVersion: dynamic(() => import('@apps/profile/be/eneco/native/src/AppInfo/AppInfo')),
  CustomerDetailsHeader: dynamic(
    () => import('@apps/profile/be/eneco/native/src/CustomerDetailsHeader/CustomerDetailsHeader'),
  ),
  ShareLocation: dynamic(() => import('@apps/profile/be/eneco/native/src/ShareLocation/ShareLocation')),
  SignOutButton: dynamic(() => import('@apps/profile/be/eneco/native/src/SignOutButton/SignOutButton')),
  PushNotificationToggles: dynamic(
    () => import('@apps/profile/be/eneco/native/src/PushNotificationsToggles/PushNotificationToggles'),
  ),
  DashboardServiceError: dynamic(
    () => import('@apps/dashboard/be/eneco/native/src/components/DashboardServiceError/DashboardServiceError'),
  ),

  EnergyProfileCard: dynamic(
    () => import('@apps/dashboard/be/eneco/native/src/components/EnergyProfileCard/EnergyProfileCard'),
  ),
  MonthSummaryCard: dynamic(
    () => import('@apps/dashboard/be/eneco/native/src/components/MonthSummaryCard/MonthSummaryCard'),
  ),
  MotivationCard: dynamic(() => import('@apps/dashboard/be/eneco/native/src/components/MotivationCard/MotivationCard')),
  DashboardHeader: dynamic(
    () => import('@apps/dashboard/be/eneco/native/src/components/DashboardHeader/DashboardHeader'),
  ),
  GeneralInformationalBanner: dynamic(
    () =>
      import('@apps/dashboard/be/eneco/native/src/components/GeneralInformationalBanner/GeneralInformationalBanner'),
  ),
  UsageCards: dynamic(() => import('@apps/dashboard/be/eneco/native/src/components/UsageCards/UsageCards')),
  AwaitingFluviusConfirmation: dynamic(
    () =>
      import('@apps/mandate/be/eneco/native/src/components/AwaitingFluviusConfirmation/AwaitingFluviusConfirmation'),
  ),
  FluviusOnboardingButton: dynamic(
    () => import('@apps/mandate/be/eneco/native/src/components/FluviusOnboardingButton/FluviusOnboardingButton'),
  ),
  MandateStatus: dynamic(() => import('@apps/mandate/be/eneco/native/src/components/MandateStatus/MandateStatus')),
  SmartChargingConnectionPanel: dynamic(
    () => import('@apps/hems/be/eneco/native/src/components/SmartChargingConnection/SmartChargingConnectionPanel'),
  ),
  SmartChargingConnectionIssue: dynamic(
    () => import('@apps/hems/be/eneco/native/src/components/SmartChargingConnectionIssue/SmartChargingConnectionIssue'),
  ),
  MandateWarning: dynamic(() => import('@apps/mandate/be/eneco/native/src/components/MandateWarning/MandateWarning')),
  NativeUsage: dynamic(() => import('@apps/usage/be/eneco/native/src/components/UsageOverview/UsageOverview')),
  EnergyProfile: dynamic(() => import('@apps/energyprofile/be/eneco/native/src/EnergyProfileFlow')),
  HomeProfileInsightsApp: dynamic(() => import('@apps/energyprofile/be/eneco/native/src/EnergyProfileOverview')),
  RevokePrivacy: dynamic(() => import('@apps/profile/be/eneco/native/src/RevokePrivacy/RevokePrivacy')),
  PasswordDetailsForm: dynamic(
    () => import('@apps/profile/shared/multilabel/src/PasswordDetailsForm/PasswordDetailsForm'),
  ),
  UsernameDetailsForm: dynamic(
    () => import('@apps/profile/shared/multilabel/src/UsernameDetailsForm/UsernameDetailsForm'),
  ),
  RemoveAccount: dynamic(() => import('@apps/profile/be/eneco/native/src/RemoveAccount/RemoveAccount')),
  FeatureContentCard: dynamic(
    () => import('@apps/dashboard/be/eneco/native/src/components/FeatureContentCard/FeatureContentCard'),
  ),
  SmartChargingCompatibilityCheck: dynamic(
    () =>
      import(
        '@apps/hems/be/eneco/native/src/components/SmartChargingCompatibilityCheck/SmartChargingCompatibilityCheck'
      ),
  ),
  SmartChargingCompatibilityCheckResult: dynamic(
    () =>
      import(
        '@apps/hems/be/eneco/native/src/components/SmartChargingCompatibilityCheck/SmartChargingCompatibilityCheckResult'
      ),
  ),
  SmartChargingConsentButton: dynamic(
    () => import('@apps/hems/be/eneco/native/src/components/SmartChargingConsentButton/SmartChargingConsentButton'),
  ),
  SmartChargingSessionDetails: dynamic(
    () => import('@apps/hems/be/eneco/native/src/components/SmartChargingSessionDetails/SmartChargingSessionDetails'),
  ),
  SmartChargingQuickLinksAndReward: dynamic(
    () =>
      import(
        '@apps/hems/be/eneco/native/src/components/SmartChargingQuickLinksAndReward/SmartChargingQuickLinksAndReward'
      ),
  ),
  SmartChargingDefaultSchedule: dynamic(
    () => import('@apps/hems/be/eneco/native/src/components/SmartChargingDefaultSchedule/SmartChargingDefaultSchedule'),
  ),
  StopSmartChargingButton: dynamic(
    () => import('@apps/hems/be/eneco/native/src/components/StopSmartChargingButton/StopSmartChargingButton'),
  ),
  StopSmartChargingNotification: dynamic(
    () =>
      import('@apps/hems/be/eneco/native/src/components/StopSmartChargingNotification/StopSmartChargingNotificiation'),
  ),
  SmartChargingProfileOverview: dynamic(
    () => import('@apps/hems/be/eneco/native/src/components/SmartChargingProfileOverview/SmartChargingProfileOverview'),
  ),
  SmartChargingCurrentLoadingSchedule: dynamic(
    () =>
      import(
        '@apps/hems/be/eneco/native/src/components/SmartChargingCurrentLoadingSchedule/SmartChargingCurrentLoadingSchedule'
      ),
  ),
  SmartChargingInsights: dynamic(
    () => import('@apps/hems/be/eneco/native/src/components/SmartChargingInsights/SmartChargingInsights'),
  ),

  ProductPurchaseFlow: dynamic(() => import('@apps/launchpad/be/eneco/native/src/ProductPurchase/ProductPurchaseFlow')),
  ProductPurchaseResult: dynamic(
    () => import('@apps/launchpad/be/eneco/native/src/ProductPuchaseResult/ProductPurchaseResult'),
  ),
  SmartChargingSchedulingToggle: dynamic(
    () =>
      import('@apps/hems/be/eneco/native/src/components/SmartChargingSchedulingToggle/SmartChargingSchedulingToggle'),
  ),

  /* SITECORE COMPONENTS */
  ButtonLink: dynamic(() => import('@sitecore/components/content/ButtonLink/ButtonLink')),
  Image: dynamic(() => import('@sitecore/components/content/Image/Image')),
  RichText: dynamic(() => import('@sitecore/components/content/RichText/RichText')),
  NotificationBox: dynamic(() => import('@sitecore/components/content/NotificationBox/NotificationBox')),
  Accordion: dynamic(() => import('@sitecore/components/content/Accordion/Accordion')),
  TitleTextCTA: dynamic(() => import('@sitecore/components/content/TitleTextCTA/TitleTextCTA')),
  Checklist: dynamic(() => import('@sitecore/components/content/Checklist/Checklist')),
  StepExplainer: dynamic(() => import('@sitecore/components/content/StepExplainerVertical/StepExplainerVertical')),

  /* NATIVE SITECORE COMPONENTS */
  BottomNavigationsButtons: dynamic(
    () => import('@sitecore/native-components/content/HemsBottomNavButtons/HemsBottomNavButtons'),
  ),
  BottomMenu: dynamic(() => import('@sitecore/native-components/content/BottomMenu/BottomMenu')),
  HEMSMinimalNavigationBar: dynamic(
    () => import('@sitecore/native-components/content/HemsMinimalNavigationBar/HemsMinimalNavigationBar'),
  ),
  TopBar: dynamic(() => import('@sitecore/native-components/content/TopBar/TopBar')),
  IconLink: dynamic(() => import('@sitecore/native-components/content/IconLink/IconLink')),
  PageTitle: dynamic(() => import('@sitecore/native-components/content/PageTitle/PageTitle')),
  TopNavigation: dynamic(() => import('@sitecore/native-components/content/TopNavigation/TopNavigation')),
  RealtimeContent: dynamic(() => import('@sitecore/native-components/content/RealtimeContent/RealtimeContent')),
  Bleed: dynamic(() => import('@sitecore/native-components/content/Bleed/Bleed')),
  ErrorMessageContainer: dynamic(
    () => import('@sitecore/native-components/content/ErrorMessageContainer/ErrorMessageContainer'),
  ),
  InsightsTutorial: dynamic(() => import('@sitecore/native-components/content/InsightsTutorial/InsightsTutorial')),
};
